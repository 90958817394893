import React from 'react';
import PropTypes from 'prop-types';
import icChecked from '../../assets/images/icon-check_box_checked.svg';
import icUnchecked from '../../assets/images/icon-check_box_unchecked.svg';

import {
  StyledCheckbox,
  StyledCheckboxLabel,
  Icon,
} from './styled';

const Checkbox = ({
  onChange, checked, label, className, disabled,
}) => {
  const handleCheck = () => {
    onChange(!checked);
  };

  return (
    <StyledCheckbox onClick={handleCheck} className={className} disabled={disabled}>
      <Icon src={checked ? icChecked : icUnchecked} disabled={disabled} />
      <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: null,
  label: null,
  disabled: false,
};

export default Checkbox;
