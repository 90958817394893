import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Api from '../../services/api';
import ToastApi from '../../services/toast';

import InputField from '../../components/inputField';
import Button from '../../components/button';
import ModalRequest from '../../components/modal/modalRequest';

import {
  Wrapper,
  Title,
  DetailsDiv,
  DivInputHours,
  DivInputCollaborator,
  DivInputs,
  DivInputTitle,
  DivInputType,
  DivInputLocal,
  DivInputDetails,
  DivStyled,
  LinkDetails,
  LabelStyled,
  ButtonsContainer,
  DivButton,
} from './styled';

const ActivityRequestDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const [rejectionReply, setRejectionReply] = useState('');
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);
  const activityId = location.state.id;

  const handleRejectActivity = () => {
    Api.rejectActivity(activityId, rejectionReply).then(() => {
      setShowModalReject(!showModalReject);
      ToastApi.success('Atividade rejeitada com sucesso.');
      history.push('/solicitacoes-atividade');
    }).catch(() => {
      ToastApi.error('Descreva o motivo da rejeição da atividade.');
      setShowModalReject(!showModalReject);
    });
  };

  const handleApproveActivity = () => {
    Api.approveActivity(activityId).then(() => {
      setShowModalApprove(!showModalApprove);
      ToastApi.success('Atividade aprovada com sucesso.');
      history.push('/solicitacoes-atividade');
    }).catch(() => {
      ToastApi.error('não foi possivel completar, verifique sua conexão.');
    });
  };

  const handleLink = (link) => {
    if (link.length > 95) {
      const newLink = link.slice(0, 95);
      return `${newLink}...`;
    }
    return link;
  };

  const handleDate = (date) => {
    const [year, month, day] = date.split('-');
    const dateFormatter = `${day}/${month}/${year}`;
    return dateFormatter;
  };

  return (
    <Wrapper>
      {showModalReject && (
        <ModalRequest
          onClickCloseModal={() => setShowModalReject(!showModalReject)}
          onClickReject={() => handleRejectActivity()}
          message="Descreva o motivo da rejeição da solicitação da atividade:"
          title="REJEITAR SOLICITAÇÃO de atividade"
          value={rejectionReply}
          onChange={(e) => setRejectionReply(e.target.value)}
          reply
        />
      )}
      {showModalApprove && (
        <ModalRequest
          onClickCloseModal={() => setShowModalApprove(!showModalApprove)}
          onClickReject={() => handleApproveActivity()}
          message="Tem certeza que irá aprovar solicitação de atividade?"
          title="APROVAR SOLICITAÇÃO DE ATIVIDADE"
        />
      )}
      <Title>Lista de solicitações de atividade / Detalhe de solicitação</Title>
      <DetailsDiv>
        <DivInputs>
          <DivInputHours>
            <InputField
              type="text"
              value={handleDate(location.state.finishDate)}
              placeholder="Data"
              disabled
            />
          </DivInputHours>
          <DivInputCollaborator>
            <InputField
              type="text"
              value={location.state.user.nickname}
              placeholder="Colaborador"
              disabled
            />
          </DivInputCollaborator>
        </DivInputs>
        <DivInputs>
          <DivInputTitle>
            <InputField
              type="text"
              value={location.state.title}
              placeholder="Título da Atividade"
              disabled
            />
          </DivInputTitle>
          <DivInputType>
            <InputField
              type="text"
              value={location.state.type.title}
              placeholder="Tipo da Atividade"
              disabled
            />
          </DivInputType>
        </DivInputs>
        <DivInputs>
          <DivInputLocal>
            <InputField
              type="text"
              value={location.state.place}
              placeholder="Local da Atividade"
              disabled
            />
          </DivInputLocal>
        </DivInputs>
        <DivInputs>
          <DivInputDetails>
            <DivStyled>
              <LinkDetails>
                <a href={location.state.link} target="_blank" rel="noreferrer noopener">{handleLink(location.state.link)}</a>
              </LinkDetails>
              <LabelStyled>Detalhes da Atividade</LabelStyled>
            </DivStyled>
          </DivInputDetails>
        </DivInputs>
        <ButtonsContainer>
          <DivButton>
            <Button
              onClick={() => setShowModalApprove(true)}
              variant="primary"
            >
              aprovar
            </Button>
          </DivButton>
          <Button
            onClick={() => setShowModalReject(true)}
            variant="exclusion"
          >
            rejeitar
          </Button>
        </ButtonsContainer>
      </DetailsDiv>
    </Wrapper>
  );
};

export default ActivityRequestDetails;
