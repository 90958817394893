import React from 'react';
import PropTypes from 'prop-types';

import TableHeader from '../tableHeader';
import TableCells from '../tableCells';

import {
  Table,
  TableRow,
  TableBody,
} from './styled';

const BenefitsTable = ({ benefits, editBenefit, deleteBenefit }) => (
  <Table>
    <TableHeader headerText1="prêmios cadastrados" headerText2="status estoque" />
    <TableBody>
      {benefits.map((item) => (
        <TableRow key={item.id}>
          <TableCells
            title={item.title}
            status={item.available}
            coins={item.coins}
            editItem={() => editBenefit(item)}
            deleteItem={() => deleteBenefit(item.id)}
          />
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

BenefitsTable.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  editBenefit: PropTypes.func.isRequired,
  deleteBenefit: PropTypes.func.isRequired,
};

export default BenefitsTable;
