import styled from 'styled-components';

const InputStyled = styled.input`
  height: 40px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  color: #2E3033;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  box-sizing: border-box;
  border: ${({ invalid }) => (invalid
    ? '1px solid #FF0000'
    : '1px solid #E8E8E8')};
  &::placeholder {
    color: #BEBEBE;
  }
  &:focus {
    border: 1px solid #8C66FF;
  }
`;

export {
  InputStyled,
};
