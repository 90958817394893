import styled from 'styled-components';

const ModalAll = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  border: 1px;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter:blur(4px);
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;

`;
const ModalDisplay = styled.div`
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  width: 754px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 36px;
  background-color: #7052CC;
  border-radius: 8px 8px 0 0;
  padding-left: 24px;
`;
const ModalHeaderText = styled.div`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & button{
    margin-left: 16px;
  }
`;

const ModalBody = styled.div`
  padding: 32px 24px 24px 24px;
`;

const TextBody = styled.p`
  color: #2E3033;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  outline: none;
  height: 68px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 12px;
  color: #2E3033;
  box-sizing: border-box;
  transition: 0.3s ease-out;
  resize: none;
  margin-bottom: 15px;
  border: 1px solid #8A9199;
  &:focus {
    border: 1px solid #8C66FF;
  }
`;

export {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  ModalBody,
  TextBody,
  TextArea,
};
