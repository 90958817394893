import styled, { css } from 'styled-components';

const primaryStyle = css`
  background: #00D991;  
  border: 1px solid #00D991;
  width: 34px;
`;

const secondaryStyle = css`
  background: #FFC619;;  
  border: 1px solid #FFC619;
  width: 70px;
`;

const getVariantStyle = (variant) => {
  if (variant === 'secondary') {
    return secondaryStyle;
  }
  return primaryStyle;
};

const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 12px;
  border-radius: 4px;
  ${({ variant }) => getVariantStyle(variant)}
`;

const BadgeText = styled.p`
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

export {
  StyledBadge,
  BadgeText,
};
