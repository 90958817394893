import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const Icon = styled(ReactSVG)`
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0px 16px;
  border-radius: 8px;
  cursor: pointer;
  background: #FCFCFC;  
  border: 1px solid #8C66FF;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #D9CCFF;
  }
`;

export {
  StyledButton,
  Icon,
};
