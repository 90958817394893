import React from 'react';
import PropTypes from 'prop-types';

import {
  InputStyled,
} from './styled';

const InputLogin = ({
  onChange, value, placeholder, invalid, type,
}) => (
  <InputStyled
    noValidate
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    invalid={invalid}
    type={type}
  />
);

InputLogin.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

InputLogin.defaultProps = {
  type: 'text',
};

export default InputLogin;
