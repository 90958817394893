import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DivLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px;
  align-self: center;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(74, 50, 146, 0.35);
  border-radius: 15px;
  z-index: 1000;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #6F4FD4;
  padding: 0 0 38px 0;
`;

const SubTitle = styled.h6`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #6247B2;
  padding: 0 16px 32px 16px;
`;

const Symbol = styled(ReactSVG)`
  width: 204px;
  height: 68px; 
`;

const DivInput = styled.div`
  width: 110%;
  padding-bottom: 20px;
`;

const DivButton = styled.div`
  width: 110%;
  padding: 0 0 10px 0;
`;

const YellowLoginImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const PurpleLoginImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

const DivError = styled.div`
  width: 100%;
  height: 12px;
  padding-bottom: 30px;
`;

const TextError = styled.p`
  font-size: 10px;
  color: #E61B1B;
  text-align: center;
`;

export {
  Wrapper,
  DivLogin,
  Title,
  SubTitle,
  Symbol,
  DivInput,
  DivButton,
  YellowLoginImage,
  PurpleLoginImage,
  DivError,
  TextError,
};
