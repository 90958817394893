import React from 'react';

import {
  TableRow,
  TableHead,
  HeaderText,
  HeaderLarge,
  DivCollapseAlign,
} from './styled';

const TableHeaderRecord = () => (
  <TableHead>
    <TableRow>
      <HeaderLarge>
        <HeaderText>Nome completo</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>data/hora</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>TIPO DE ATIVIDADE</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>pontos</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>status</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>responsável</HeaderText>
      </HeaderLarge>
      <DivCollapseAlign />
    </TableRow>
  </TableHead>
);

export default TableHeaderRecord;
