import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const Wrapper = styled.div`
  width: calc(100% - 88px);
  padding: 44px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7e5ce5;
  margin-bottom: 24px;
`;

const CardsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const Card = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  padding: 20px;
  align-self: center;
  background: #FFFFFF;
  border: 1px solid #F2F9FF;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px rgba(25, 6, 81, 0.1);
  border-radius: 10px;
`;

const CardTitle = styled.h6`
  font-size: 18px;
  line-height: 20px;
  color: #7052CC;
  margin-bottom: 12px;
`;

const CardRequestNumber = styled.h6`
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  display: flex;
  align-items: flex-start;
  color: #17BCE6;
  margin-bottom: 12px;
`;

const CardRequestLink = styled.p`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #1AD1FF;
`;

const Icon = styled(ReactSVG)`
  width: 32px;
  height: 32px;
  path{
      fill: #7052CC;
    }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export {
  Title,
  Wrapper,
  CardsDiv,
  Card,
  CardTitle,
  CardRequestNumber,
  CardRequestLink,
  Icon,
  Row,
};
