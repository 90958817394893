import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip';

import {
  StyledDivUpload,
  StyledLabel,
  StyledInput,
  Icon,
  StyledText,
  DivNotError,
  ErrorText,
  DivInput,
  DivError,
} from './styled';

const errorMessage = 'Este campo é obrigatório.';

const UploadField = ({
  onChange, iconUpload, iconInfo, children, imageName, iconReward, invalid,
}) => (

  <DivInput>
    <StyledDivUpload>
      <StyledLabel invalid={invalid}>
        {imageName ? <Icon src={iconReward} /> : <Icon src={iconUpload} />}
        <StyledInput type="file" accept=".svg" onChange={onChange} invalid={invalid} />
        <StyledText>{imageName || children}</StyledText>
      </StyledLabel>
      <Tooltip text="Formato SVG 24x24 px">
        <Icon src={iconInfo} />
      </Tooltip>
    </StyledDivUpload>
    <DivError>
      {invalid ? <ErrorText>{errorMessage}</ErrorText> : <DivNotError />}
    </DivError>
  </DivInput>
);

UploadField.propTypes = {
  onChange: PropTypes.func.isRequired,
  iconUpload: PropTypes.string.isRequired,
  iconInfo: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  invalid: PropTypes.bool.isRequired,
  imageName: PropTypes.string,
  iconReward: PropTypes.string.isRequired,
};

UploadField.defaultProps = {
  imageName: '',
};

export default UploadField;
