import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  DivInputTextCOP,
  DivInputTextPoints,
  DivInputs,
  DivTextArea,
  DivInputGracePeriod,
  DivToggleStatus,
  DivTextAreaToogle,
  ModalBody,
  DivBadge,
  DivUpload,
} from './styled';

import ToastApi from '../../../services/toast';
import Api from '../../../services/api';
import Button from '../../button';
import UploadButton from '../../uploadButton';
import InputField from '../../inputField';
import TextArea from '../../textArea';
import Toggle from '../../toggle';
import Badge from '../../badge';

import uploadIcon from '../../../assets/images/icon-UploadFile.svg';
import infoIcon from '../../../assets/images/icon-Info.svg';
import defaultIconReward from '../../../assets/images/icon-defaultReward.svg';

const placeholderName = 'Nome do prêmio';
const placeholderInstruction = 'Instruções para solicitação de prêmio';
const placeholderPoint = 'Pontuação';
const placeholderGracePeriod = 'Prazo de carência (em dias)';

const Modal = ({ onClickCloseModal, editData, onSaveSuccess }) => {
  const [title, setTitle] = useState(editData.title);
  const [instructions, setInstructions] = useState(editData.instructions);
  const [coins, setCoins] = useState(editData.coins);
  const [available, setAvailable] = useState(editData.available);
  const [image, setImage] = useState(editData.image);
  const [gracePeriod, setGracePeriod] = useState(editData.gracePeriod);

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [coinsError, setCoinsError] = useState(false);

  const [alreadyClickOnSave, setAlreadyClickOnSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setTitleError(!title || title.length === 0);
    }
  }, [title]);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setCoinsError(!coins || coins.length < 0);
    }
  }, [coins]);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setDescriptionError(!instructions || instructions.length === 0);
    }
  }, [instructions]);

  const HandleEditBenefit = () => {
    const titleHasError = !title || title.length === 0;
    const descriptionHasError = !instructions || instructions.length === 0;
    const coinsHasError = !coins || coins < 0;

    setAlreadyClickOnSave(true);

    if (titleHasError || descriptionHasError || coinsHasError) {
      setTitleError(titleHasError);
      setDescriptionError(descriptionHasError);
      setCoinsError(coinsHasError);
      return;
    }

    setIsSaving(true);

    let imageToSave = image;

    if (image.filename === editData.image.filename || image.name === editData.image.filename) {
      imageToSave = null;
    }

    Api.editBenefits(
      editData.id,
      title,
      instructions,
      coins,
      available,
      gracePeriod,
      imageToSave,
    ).then(() => {
      onSaveSuccess({
        id: editData.id,
        title,
        coins,
        instructions,
        available,
        gracePeriod,
        image,
      });
      ToastApi.success('Prêmio editado com sucesso');
    }).catch(() => {
      ToastApi.error('Não foi possivel editar o prêmio');
    }).finally(() => {
      setIsSaving(false);
      onClickCloseModal();
    });
  };

  const handleOnlyNumbersAllowed = (e) => {
    const numberPattern = /\D/gi;

    const sanitizedValue = e.target.value.replace(numberPattern, '');

    return sanitizedValue;
  };

  return (
    <ModalAll>
      <ModalDisplay>
        <ModalHeader>
          <ModalHeaderText>editar prêmio</ModalHeaderText>
        </ModalHeader>
        <ModalBody>
          <DivInputs>
            <DivInputTextCOP>
              <InputField
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder={placeholderName}
                invalid={titleError}
              />
            </DivInputTextCOP>
            <DivInputTextPoints>
              <InputField
                onChange={(e) => setCoins(handleOnlyNumbersAllowed(e))}
                value={coins}
                placeholder={placeholderPoint}
                invalid={coinsError}
                length="4"
              />
            </DivInputTextPoints>
          </DivInputs>
          <DivTextAreaToogle>
            <DivTextArea>
              <TextArea
                type="text"
                onChange={(e) => setInstructions(e.target.value)}
                value={instructions}
                placeholder={placeholderInstruction}
                invalid={descriptionError}
              />
            </DivTextArea>
            <DivInputGracePeriod>
              <InputField
                onChange={(e) => setGracePeriod(handleOnlyNumbersAllowed(e))}
                value={gracePeriod}
                placeholder={placeholderGracePeriod}
                length="3"
              />
              <DivToggleStatus>
                <DivBadge>
                  <p>Status:</p>
                  {available ? <Badge variant="primary" value="ativo" /> : <Badge variant="secondary" value="desativado" />}
                </DivBadge>
                <Toggle
                  onChange={() => setAvailable(!available)}
                  checked={available}
                />
              </DivToggleStatus>
            </DivInputGracePeriod>
          </DivTextAreaToogle>
          <DivUpload>
            <UploadButton
              onChange={(e) => setImage(e.target.files[0])}
              placeholder="Upload Icone"
              iconUpload={uploadIcon}
              iconInfo={infoIcon}
              imageName={image?.filename || image?.name}
              iconReward={defaultIconReward}
            >
              Upload Icone
            </UploadButton>
          </DivUpload>
          <ButtonsContainer>
            <Button
              onClick={onClickCloseModal}
              variant="tertiary"
              disabled={isSaving}
            >
              cancelar
            </Button>
            <Button
              onClick={() => HandleEditBenefit()}
              variant="primary"
              disabled={isSaving}
            >
              salvar
            </Button>
          </ButtonsContainer>
        </ModalBody>
      </ModalDisplay>
    </ModalAll>
  );
};

Modal.propTypes = {
  onClickCloseModal: PropTypes.func.isRequired,
  editData: PropTypes.objectOf(PropTypes.any).isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

export default Modal;
