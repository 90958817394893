import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  CheckBoxDiv,
  DivInputTextCOP,
  DivInputTextPoints,
  DivInputs,
  DivTextArea,
  DivToggleStatus,
  DivTest,
  ModalBody,
  DivBadge,
} from './styled';

import ToastApi from '../../../services/toast';
import Api from '../../../services/api';
import Checkbox from '../../checkBox';
import Button from '../../button';
import InputField from '../../inputField';
import TextArea from '../../textArea';
import Toggle from '../../toggle';
import Badge from '../../badge';

const nameLabel = 'Obrigar a informação de carga horaria';
const nameLabel2 = 'Multiplicar pontos por hora';
const placeholderName = 'Nome da Atividade';
const placeholderInstruction = 'Instruções para Atividade';
const placeholderPoint = 'Pontuação';

const Modal = ({ onClickCloseModal, editData, onSaveSuccess }) => {
  const [flagHours, setFlagHours] = useState(editData.flagHours);
  const [multiplier, setMultiplier] = useState(editData.multiplier);
  const [title, setTitle] = useState(editData.title);
  const [description, setDescription] = useState(editData.description);
  const [coins, setCoins] = useState(editData.coins);
  const [available, setAvailable] = useState(editData.available);

  const [titleError, setTitleError] = useState(false);
  const [coinsError, setCoinsError] = useState(false);

  const [alreadyClickOnSave, setAlreadyClickOnSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setTitleError(!title || title.length === 0);
    }
  }, [title]);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setCoinsError(!coins || coins.length === 0);
    }
  }, [coins]);

  const HandleEditActivitie = () => {
    const titleHasError = !title || title.length === 0;
    const coinsHasError = !coins || coins < 0;

    setAlreadyClickOnSave(true);

    if (titleHasError || coinsHasError) {
      setTitleError(titleHasError);
      setCoinsError(coinsHasError);
      return;
    }

    setIsSaving(true);

    Api.editActivitie(
      editData.id,
      title,
      description,
      coins,
      multiplier,
      flagHours,
      available,
    ).then(() => {
      onSaveSuccess({
        id: editData.id,
        title,
        description,
        coins,
        multiplier,
        flagHours,
        available,
      });
      ToastApi.success('Atividade editada com sucesso');
    }).catch(() => {
      ToastApi.error('Não foi possivel editar a atividade');
    }).finally(() => {
      setIsSaving(false);
      onClickCloseModal();
    });
  };

  const handleOnChangeCoins = (e) => {
    const numberPattern = /\D/gi;

    const sanitizedValue = e.target.value.replace(numberPattern, '');

    setCoins(sanitizedValue);
  };

  const handleCheckFlag = () => {
    setFlagHours(!flagHours);
    if (multiplier) {
      setMultiplier(false);
    }
  };

  return (
    <ModalAll>
      <ModalDisplay>
        <ModalHeader>
          <ModalHeaderText>Editar atividade</ModalHeaderText>
        </ModalHeader>
        <ModalBody>
          <DivInputs>
            <DivInputTextCOP>
              <InputField
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder={placeholderName}
                invalid={titleError}
              />
            </DivInputTextCOP>
            <DivInputTextPoints>
              <InputField
                onChange={handleOnChangeCoins}
                value={coins}
                placeholder={placeholderPoint}
                invalid={coinsError}
                length="4"
              />
            </DivInputTextPoints>
          </DivInputs>
          <DivTest>
            <DivTextArea>
              <TextArea
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder={placeholderInstruction}
              />
            </DivTextArea>
            <DivToggleStatus>
              <DivBadge>
                <p>Status:</p>
                {available ? <Badge variant="primary" value="ativo" /> : <Badge variant="secondary" value="desativado" />}
              </DivBadge>
              <Toggle
                onChange={() => setAvailable(!available)}
                checked={available}
              />
            </DivToggleStatus>
          </DivTest>
          <CheckBoxDiv>
            <Checkbox
              label={nameLabel}
              checked={flagHours}
              onChange={handleCheckFlag}
            />
            <Checkbox
              label={nameLabel2}
              checked={multiplier}
              onChange={() => setMultiplier(!multiplier)}
              disabled={!flagHours}
            />
          </CheckBoxDiv>
          <ButtonsContainer>
            <Button
              onClick={onClickCloseModal}
              variant="tertiary"
              disabled={isSaving}
            >
              cancelar
            </Button>
            <Button
              onClick={() => HandleEditActivitie()}
              variant="primary"
              disabled={isSaving}
            >
              salvar
            </Button>
          </ButtonsContainer>
        </ModalBody>
      </ModalDisplay>
    </ModalAll>
  );
};

Modal.propTypes = {
  onClickCloseModal: PropTypes.func.isRequired,
  editData: PropTypes.objectOf(PropTypes.any).isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

export default Modal;
