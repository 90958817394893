import styled from 'styled-components';

const TableHead = styled.thead`
  background-color: #7052CC;
  display: flex;
  flex: 1;
  border-radius: 8px 8px 0px 0px;
`;

const HeaderLarge = styled.th`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
`;

const HeaderSmall = styled.th`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.p`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #FCFCFC;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
  &:nth-child(even) {
  background: #FAFAFA;
  }
`;

export {
  TableRow,
  TableHead,
  HeaderText,
  HeaderSmall,
  HeaderLarge,
};
