import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const StyledHeader = styled.header`
  min-height: 60px;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
`;
const TextTituloHeader = styled.h1`
  font-weight: 500;
  font-size: 24px;
  color: #7052CC;
  margin-left: 44px;
`;
const DivSideText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 20px;  
`;
const TextHeaderName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7052cc;
  padding: 2px;
`;
const TextHeaderEmail = styled.p`
  font-weight: 00;
  font-size: 12px;
  line-height: 14px;
  color: #7052cc;
`;

const ExitButton = styled.button`
  display: flex;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  padding-right: 20;
`;

const Icon = styled(ReactSVG)`
  width: 20px;
  height: 20px;
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export {
  StyledHeader,
  TextTituloHeader,
  TextHeaderName,
  TextHeaderEmail,
  DivSideText,
  ExitButton,
  Icon,
  Div,
};
