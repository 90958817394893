import React, { useEffect, useState, useRef } from 'react';

import ToastApi from '../../services/toast';
import Api from '../../services/api';

import ModalRegisterTask from '../../components/modal/modalRegisterTask';
import ModalEditTask from '../../components/modal/modalEditTask';
import ModalDeleteTask from '../../components/modal/modalDeleteTask';
import Button from '../../components/button';
import ActivitiesTable from '../../components/activitiesTable';

import ButtonIcon from '../../assets/images/icon-Registrar.svg';

import {
  Title,
  Wrapper,
  ButtonContainer,
  TableDiv,
} from './styled';

const RegisterActivities = () => {
  const [activities, setActivities] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const deleteActivityIdRef = useRef(null);
  const editActivityRef = useRef(null);

  useEffect(() => {
    Api.getActivities().then((res) => {
      setActivities(res);
    }).catch(() => {
      ToastApi.error('Não pode ser exibida a lista');
    });
  }, []);

  const deleteActivitie = (id) => {
    Api.deleteActivitie(id).then(() => {
      setActivities(activities.filter((a) => (a.id !== id)));
      ToastApi.success('Planilha salva com sucesso.');
    }).catch(() => {
      ToastApi.error('Não é possível excluir essa atividade.');
    }).finally(() => {
      setIsModalDeleteVisible(false);
    });
  };

  return (
    <Wrapper>
      {isModalVisible && (
        <ModalRegisterTask
          onClickCloseModal={() => setIsModalVisible(!isModalVisible)}
          onSaveSuccess={(newActivitie) => {
            const newActivities = [...activities];
            newActivities.push(newActivitie);
            newActivities.sort((a, b) => {
              const textA = a.title.toUpperCase();
              const textB = b.title.toUpperCase();
              const biggerThen = (textA > textB) ? 1 : 0;
              return (textA < textB) ? -1 : biggerThen;
            });
            setActivities(newActivities);
          }}
        />
      )}
      {isModalDeleteVisible && (
        <ModalDeleteTask
          onClickSecondaryButton={() => setIsModalDeleteVisible(!isModalDeleteVisible)}
          onClickDeleteButton={() => deleteActivitie(deleteActivityIdRef.current)}
          message="Tem certeza que deseja excluir essa atividade?"
        />
      )}
      {isModalEditVisible && (
        <ModalEditTask
          onClickCloseModal={() => setIsModalEditVisible(!isModalEditVisible)}
          editData={editActivityRef.current}
          onSaveSuccess={(editedActivity) => {
            const index = activities.findIndex((a) => a.id === editedActivity.id);
            const newActivities = [...activities];
            newActivities.splice(index, 1, editedActivity);
            setActivities(newActivities);
          }}
        />
      )}
      <Title>Lista de atividades</Title>
      <ButtonContainer>
        <Button
          onClick={() => setIsModalVisible(!isModalVisible)}
          variant="secondary"
          icon={ButtonIcon}
        >
          cadastrar atividades
        </Button>
      </ButtonContainer>
      <TableDiv>
        <ActivitiesTable
          activities={activities}
          editActivity={(activity) => {
            setIsModalEditVisible(!isModalEditVisible);
            editActivityRef.current = activity;
          }}
          deleteActivity={(id) => {
            setIsModalDeleteVisible(!isModalDeleteVisible);
            deleteActivityIdRef.current = id;
          }}
        />
      </TableDiv>
    </Wrapper>
  );
};

export default RegisterActivities;
