import React from 'react';
import PropTypes from 'prop-types';

import {
  TableRow,
  TableHead,
  HeaderText,
  HeaderSmall,
  HeaderLarge,
} from './styled';

const tableHeader = ({
  headerText1,
  headerText2,
  headerText3,
  headerText4,
}) => (
  <TableHead>
    <TableRow>
      <HeaderSmall>
        <HeaderText>{headerText1}</HeaderText>
      </HeaderSmall>
      <HeaderLarge>
        <HeaderText>{headerText2}</HeaderText>
      </HeaderLarge>
      <HeaderLarge>
        <HeaderText>{headerText3}</HeaderText>
      </HeaderLarge>
      <HeaderSmall>
        <HeaderText>{headerText4}</HeaderText>
      </HeaderSmall>
    </TableRow>
  </TableHead>
);

tableHeader.propTypes = {
  headerText1: PropTypes.string.isRequired,
  headerText2: PropTypes.string.isRequired,
  headerText3: PropTypes.string.isRequired,
  headerText4: PropTypes.string.isRequired,
};

export default tableHeader;
