import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButton,
  Icon,
  ButtonText,
} from './styled';

const Button = ({
  children, onClick, variant, icon, disabled,
}) => (
  <StyledButton onClick={onClick} variant={variant} disabled={disabled}>
    {icon && <Icon src={icon} />}
    {children && <ButtonText>{children}</ButtonText>}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quartiary', 'search', 'exclusion', 'login']),
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'primary',
  icon: null,
  disabled: false,
  onClick: () => {},
};
export default Button;
