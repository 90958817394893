import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
  top: 96px;

  .Toastify__toast  {
    min-height: 79px
  }

  .Toastify__toast-body  {
    color:#FFFF; 
    font-size: 14px; 
  }

  .Toastify__close-button {
    align-self: auto;
    margin-left: 16px;
  }

  .Toastify__toast--error {
    background-color: #FF8C96;
    color: red;
  }

  .Toastify__toast--warning {
    background-color: #FF8C96;
  }

  .Toastify__toast--success {
    background-color: #599A42;
  }
`;

export {
  StyledToastContainer,
};
