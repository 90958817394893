import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { PageWrapper, ContentAndHeaderWrapper } from './styles';
import LateralMenu from './components/lateralMenu';
import Header from './components/header';
import Dashboard from './pages/dashboard';
import RegisterReward from './pages/registerReward';
import RegisterActivities from './pages/registerActivities';
import Record from './pages/record';
import Login from './pages/login';
import ActivityRequest from './pages/activityRequest';
import BenefitRequest from './pages/benefitRequest';
import ActivityRequestDetails from './pages/activityRequestDetails';
import BenefitRequestDetails from './pages/benefitRequestDetails';
import useAuth from './hooks/useAuth';

const publicRoutes = [
  {
    path: '/login',
    component: Login,
  },
];

const privateRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
    title: 'Dashboard',
  },
  {
    path: '/cadastroAtividade',
    component: RegisterActivities,
    title: 'Atividades',
  },
  {
    path: '/cadastroPremio',
    component: RegisterReward,
    title: 'Prêmios',
  },
  {
    path: '/relatorio',
    component: Record,
    title: 'Relatórios',
  },
  {
    path: '/solicitacoes-atividade',
    component: ActivityRequest,
    title: 'Solicitações de Atividade',
  },
  {
    path: '/solicitacoes-atividade-detalhes',
    component: ActivityRequestDetails,
    title: 'Solicitações de Atividade',
  },
  {
    path: '/solicitacoes-premio',
    component: BenefitRequest,
    title: 'Solicitações de Prêmios',
  },
  {
    path: '/solicitacoes-premio-detalhes',
    component: BenefitRequestDetails,
    title: 'Solicitações de Prêmios',
  },
];

const Routes = () => {
  const history = useHistory();
  const location = useLocation();
  const [userInfo, setUserInfo] = useAuth();
  const isAuthenticate = !!userInfo?.token;

  const renderPrivateRoutes = () => (
    <PageWrapper>
      <LateralMenu routesConfig={privateRoutes} />
      <ContentAndHeaderWrapper>
        <Header routesConfig={privateRoutes} />
        {privateRoutes.map((routeConfig) => (
          <Route path={routeConfig.path} component={routeConfig.component} />
        ))}
      </ContentAndHeaderWrapper>
    </PageWrapper>
  );

  const renderPublicRoutes = () => (
    publicRoutes.map((routeConfig) => (
      <Route path={routeConfig.path} component={routeConfig.component} />
    ))
  );

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo'));
    if (!token) {
      localStorage.removeItem('userInfo');
      setUserInfo(null);
      history.push('/login');
    }
  }, [location.pathname]);

  return (
    <Switch>
      {!isAuthenticate && renderPublicRoutes()}
      {isAuthenticate && renderPrivateRoutes()}
      <Redirect to={isAuthenticate ? '/dashboard' : '/login'} />
    </Switch>
  );
};

export default Routes;
export { publicRoutes, privateRoutes };
