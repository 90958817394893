import React from 'react';
import PropTypes from 'prop-types';

import TableHeader from '../tableHeader';
import TableCells from '../tableCells';

import {
  Table,
  TableRow,
  TableBody,
} from './styled';

const ActivitiesTable = ({ activities, editActivity, deleteActivity }) => (
  <Table>
    <TableHeader headerText1="atividades cadastradas" headerText2="status" />
    <TableBody>
      {activities.map((item) => (
        <TableRow key={item.id}>
          <TableCells
            title={item.title}
            status={item.available}
            coins={item.coins}
            multiplier={item.multiplier}
            editItem={() => editActivity(item)}
            deleteItem={() => deleteActivity(item.id)}
          />
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

ActivitiesTable.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  editActivity: PropTypes.func.isRequired,
  deleteActivity: PropTypes.func.isRequired,
};

export default ActivitiesTable;
