import styled from 'styled-components';

const DivStyled = styled.div`
  position: relative;
`;

const SelectStyled = styled.select`
  outline: none;
  height: 40px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 12px;
  color: #2E3033;
  box-sizing: border-box;
  transition: 0.3s ease-out;
  border: 1px solid #8C66FF;
  &::placeholder {
    opacity: 0;
  }
  &:focus + label {
    top: 0;
    left: 0;
    transform: translateY(-50%) scale(0.8) !important;
  }
  &:not(:placeholder-shown) + label {
    top: 0;
    left: 0;
    transform: translateY(-50%) scale(0.8) !important;
  }
  &::-webkit-inner-spin-button { 
    -webkit-appearance: none;   
  }
  &:disabled{
    background: #F6F5FA;
    border-color: #F6F5FA;
    cursor: not-allowed;  
  }
`;

const LabelStyled = styled.label`
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 4px;
  color: #8C66FF;
  transition: 0.1s ease-out;
  pointer-events: none;
  white-space: nowrap;
`;

const StyledOption = styled.option`
  color: #6B6B6B;
  background-color: #FFFFFF;
  margin: 10px;

  &:hover {
    background-color: red;
  }
`;

export {
  DivStyled,
  SelectStyled,
  LabelStyled,
  StyledOption,
};
