import React from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "./styleDate.css";


const DateComponent = ({ onChange, value, selected, placeholder }) => (
  <div className="divStyled">
    <DatePicker
        className="styleDatePicker"
        value={value}
        selected={selected} 
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        closeOnScroll={false}
      />
      <label className= "labelStyled">{placeholder}</label>
  </div>
);

DateComponent.propTypes = {
  onChange: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default DateComponent;
