import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const Icon = styled(ReactSVG)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;

const StyledLabel = styled.label`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  max-width: 178px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0px 16px;
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px dashed ${({ invalid }) => (invalid
    ? '#FF0000'
    : '#DCDAE5')};
  box-sizing: border-box;
  border-radius: 4px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #8A9299;
    color: #FFFFFF;
  }
`;

const StyledText = styled.h6`
  font-size: 16px;
  line-height: 20px;
  flex-wrap: nowrap;
  overflow: hidden;
  color: #8A9299;
  font-weight: normal;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledDivUpload = styled.div`
  display: flex;
  align-items: center;
`;

const DivNotError = styled.div`
  height: 20px;
`;

const ErrorText = styled.span`
  font-family: Quicksand;
  font-weight: 500;
  font-size: 9px;
  color: #FF0000;
`;

const DivInput = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DivError = styled.div`
  padding-left: 12px;
`;

export {
  StyledDivUpload,
  StyledLabel,
  StyledInput,
  Icon,
  StyledText,
  DivNotError,
  ErrorText,
  DivInput,
  DivError,
};
