import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconCollapseDown from '../../assets/images/icon-collapseDown.svg';
import IconCollapseUp from '../../assets/images/icon-collapseUp.svg';

import {
  CellLarge,
  CellSmall,
  CellTextLarge,
  CellTextSmall,
  DivCollapseAlign,
  DropCollapseIcon,
  Collapse,
  InfoCollapseButton,
  AlignTable,
  TableRow,
  TableRowSub,
  HeaderTextCollapseLarge,
  HeaderTextCollapseSmall,
  TableHead,
  CollapseHeaderText,
  CollapseCellLarge,
  CollapseCellSmall,
  AlignCollapse,
} from './styled';

const TableCellsRecord = ({
  name,
  status,
  activityTitle,
  initialDate,
  points,
  finalDate,
  activityType,
  local,
  link,
  hours,
  responsibleUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDate = (date) => {
    const newDate = new Date(date);
    const dateFormatter = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
    return dateFormatter;
  };

  const handleHour = (hour) => {
    const newHour = new Date(hour);
    let dateFormatter;
    const hourFormatter = newHour.getHours() + 1;
    const minuteFormatter = newHour.getMinutes();
    if (minuteFormatter < 10) {
      dateFormatter = `${hourFormatter}:0${minuteFormatter}`;
    } else {
      dateFormatter = `${hourFormatter}:${minuteFormatter}`;
    }
    return dateFormatter;
  };

  const handleStatus = () => {
    let newStatus;
    if (status === 'PENDING') {
      newStatus = 'Em análise';
    } else if (status === 'REJECTED') {
      newStatus = 'Rejeitada';
    } else {
      newStatus = 'Aprovada';
    }

    return newStatus;
  };

  const handleStrings = (str) => {
    const linkString = str.substr(0, 35);
    return linkString;
  };

  return (
    <AlignTable>
      <TableRow isOpen={isOpen}>
        <CellLarge>
          <CellTextLarge>{handleStrings(name)}</CellTextLarge>
        </CellLarge>
        <CellSmall>
          <CellTextSmall>
            {handleDate(initialDate)}
            {' '}
            |
            {' '}
            {handleHour(initialDate)}
          </CellTextSmall>
        </CellSmall>
        <CellLarge><CellTextLarge>{handleStrings(activityType)}</CellTextLarge></CellLarge>
        <CellSmall><CellTextSmall>{points}</CellTextSmall></CellSmall>
        <CellSmall><CellTextSmall>{handleStatus()}</CellTextSmall></CellSmall>
        <CellLarge><CellTextLarge>{handleStrings(responsibleUser)}</CellTextLarge></CellLarge>
        <DivCollapseAlign>
          <InfoCollapseButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <DropCollapseIcon src={IconCollapseUp} />
            ) : (
              <DropCollapseIcon src={IconCollapseDown} />
            )}
          </InfoCollapseButton>
        </DivCollapseAlign>
      </TableRow>
      {isOpen && (
        <Collapse>
          <TableHead>
            <HeaderTextCollapseLarge>
              <CollapseHeaderText>
                título da atividade
              </CollapseHeaderText>
            </HeaderTextCollapseLarge>
            <HeaderTextCollapseSmall>
              <CollapseHeaderText>
                data
              </CollapseHeaderText>
            </HeaderTextCollapseSmall>
            <HeaderTextCollapseSmall>
              <CollapseHeaderText>
                horas
              </CollapseHeaderText>
            </HeaderTextCollapseSmall>
            <HeaderTextCollapseSmall>
              <CollapseHeaderText>
                local
              </CollapseHeaderText>
            </HeaderTextCollapseSmall>
            <HeaderTextCollapseLarge>
              <CollapseHeaderText>
                link/comprovante
              </CollapseHeaderText>
            </HeaderTextCollapseLarge>
          </TableHead>
          <AlignCollapse>
            <TableRowSub>
              <CollapseCellLarge>
                <CellTextLarge>{handleStrings(activityTitle)}</CellTextLarge>
              </CollapseCellLarge>
              <CollapseCellSmall>
                <CellTextSmall>
                  {handleDate(finalDate)}
                </CellTextSmall>
              </CollapseCellSmall>
              <CollapseCellSmall><CellTextSmall>{hours}</CellTextSmall></CollapseCellSmall>
              <CollapseCellSmall>
                <CellTextSmall>
                  {handleStrings(local)}
                </CellTextSmall>
              </CollapseCellSmall>
              <CollapseCellLarge>
                <CellTextLarge>
                  <a href={link}>{handleStrings(link)}</a>
                </CellTextLarge>
              </CollapseCellLarge>
            </TableRowSub>
          </AlignCollapse>
        </Collapse>
      )}
    </AlignTable>
  );
};

TableCellsRecord.propTypes = {
  name: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  activityTitle: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  finalDate: PropTypes.string.isRequired,
  responsibleUser: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  local: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default TableCellsRecord;
