import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyles from './styles/GlobalStyles';
import Routes from './routes';
import { AuthProvider } from './hooks/useAuth';
import ToastProvider from './services/toast/ToastProvider';

const App = () => (
  <AuthProvider>
    <Router>
      <ToastProvider />
      <GlobalStyles />
      <Routes />
    </Router>
  </AuthProvider>
);

export default App;
