import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Api from '../../services/api';
import ToastApi from '../../services/toast';

import InputField from '../../components/inputField';
import TextArea from '../../components/textArea';
import Button from '../../components/button';
import ModalRequest from '../../components/modal/modalRequest';

import {
  Wrapper,
  Title,
  DetailsDiv,
  DivInputHours,
  DivInputCollaborator,
  DivInputs,
  DivInputType,
  DivInputDetails,
  ButtonsContainer,
  DivButton,
} from './styled';

const BenefitRequestDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const [rejectionReply, setRejectionReply] = useState('');
  const [showModalApprove, setShowModalApprove] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);

  const handleRejectBenefit = () => {
    Api.rejectBenefit(location.state.id, rejectionReply).then(() => {
      setShowModalReject(!showModalReject);
      ToastApi.success('Prêmio rejeitado com sucesso.');
      history.push('/solicitacoes-premio');
    }).catch(() => {
      ToastApi.error('Descreva o motivo da rejeição do prêmio.');
      setShowModalReject(!showModalReject);
    });
  };

  const handleApproveBenefit = () => {
    Api.approveBenefit(location.state.id).then(() => {
      setShowModalApprove(!showModalApprove);
      ToastApi.success('Prêmio aprovado com sucesso.');
      history.push('/solicitacoes-premio');
    }).catch(() => {
      ToastApi.error('não foi possivel completar, verifique sua conexão.');
    });
  };

  const handleDate = (date) => {
    const [year, month, day] = date.split('-');
    const dateFormatter = `${day}/${month}/${year}`;
    return dateFormatter;
  };

  return (
    <Wrapper>
      {showModalReject && (
        <ModalRequest
          onClickCloseModal={() => setShowModalReject(!showModalReject)}
          onClickReject={() => handleRejectBenefit()}
          message="Descreva o motivo da rejeição da solicitação de prêmio:"
          title="REJEITAR SOLICITAÇÃO de PRêMIOS"
          value={rejectionReply}
          onChange={(e) => setRejectionReply(e.target.value)}
          reply
        />
      )}
      {showModalApprove && (
        <ModalRequest
          onClickCloseModal={() => setShowModalApprove(!showModalApprove)}
          onClickReject={() => handleApproveBenefit()}
          message="Tem certeza que irá aprovar solicitação de prêmio?"
          title="APROVAR SOLICITAÇÃO de PRêMIOS"
        />
      )}
      <Title>Lista de solicitações de prêmios / Detalhe de solicitação</Title>
      <DetailsDiv>
        <DivInputs>
          <DivInputHours>
            <InputField
              type="text"
              value={handleDate(location.state.createdAt)}
              placeholder="Data"
              disabled
            />
          </DivInputHours>
          <DivInputCollaborator>
            <InputField
              type="text"
              value={location.state.user.nickname}
              placeholder="Colaborador"
              disabled
            />
          </DivInputCollaborator>
        </DivInputs>
        <DivInputs>
          <DivInputType>
            <InputField
              type="text"
              value={location.state.type.title}
              placeholder="Tipo de prêmio"
              disabled
            />
          </DivInputType>
        </DivInputs>
        <DivInputs>
          <DivInputDetails>
            <TextArea
              value={location.state.description || ' '}
              placeholder="Detalhes da solicitação"
              disabled
            />
          </DivInputDetails>
        </DivInputs>
        <ButtonsContainer>
          <DivButton>
            <Button
              onClick={() => setShowModalApprove(!showModalApprove)}
              variant="primary"
            >
              aprovar
            </Button>
          </DivButton>
          <Button
            onClick={() => setShowModalReject(!showModalReject)}
            variant="exclusion"
          >
            rejeitar
          </Button>
        </ButtonsContainer>
      </DetailsDiv>
    </Wrapper>
  );
};

export default BenefitRequestDetails;
