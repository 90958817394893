import styled from 'styled-components';

const DivStyled = styled.div`
  position: relative;
  flex-direction: column;
`;

const InputStyled = styled.textarea`
  outline: none;
  height: 84px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 16px 12px;
  color: #2E3033;
  box-sizing: border-box;
  transition: 0.3s ease-out;
  resize: none;
  border: ${({ invalid }) => (invalid
    ? '1px solid #FF0000'
    : '1px solid #8A9199')};
  &::placeholder {
    opacity: 0;
  }
  &:focus {
    border: 1px solid #8C66FF;
  }
  &:focus + label {
    color: #8C66FF;
    top: 0;
    left: 0;
    transform: translateY(-50%) scale(0.8) !important;
  }
  &:not(:placeholder-shown) + label {
    top: 0;
    left: 0;
    transform: translateY(-50%) scale(0.8) !important;
  }
  &::-webkit-inner-spin-button { 
    -webkit-appearance: none;   
  }
  &:disabled{
    border: 1px solid #8A9299;
    cursor: not-allowed;
    color: #2E3033;
  }
`;

const LabelStyled = styled.label`
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 12px;
  transform: translateY(-150%);
  background-color: #FFFFFF;
  padding: 4px;
  color: ${({ disabled }) => (disabled
    ? '#DCDFE9'
    : '#2E3033')};
  transition: 0.1s ease-out;
  pointer-events: none;
  white-space: nowrap;
`;

const DivNotError = styled.div`
  height: 20px;
`;

const DivError = styled.div`
  padding-left: 12px;
`;

const ErrorText = styled.span`
  font-weight: 500;
  font-size: 9px;
  color: #FF0000;
`;

export {
  DivStyled,
  InputStyled,
  LabelStyled,
  DivError,
  ErrorText,
  DivNotError,
};
