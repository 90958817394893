import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

import IconExit from '../../assets/images/icon-Exit.svg';

import {
  StyledHeader,
  TextTituloHeader,
  TextHeaderName,
  TextHeaderEmail,
  DivSideText,
  ExitButton,
  Icon,
  Div,
} from './style';

const Header = ({ routesConfig }) => {
  const [userInfo, setUserInfo] = useAuth();
  const location = useLocation();
  const [title, setTitle] = useState();

  const formattedEmail = `${userInfo.username}@softdesign.com.br`;

  const formattedName = () => {
    const userName = userInfo.nickname;
    const sanitizedName = userName.split(' ');
    return `${sanitizedName[0]} ${sanitizedName[sanitizedName.length - 1]}`;
  };

  useEffect(() => {
    const findedRoute = routesConfig.find(
      (routeConfig) => routeConfig.path.startsWith(location.pathname),
    );
    if (findedRoute) {
      setTitle(findedRoute.title);
    } else {
      setTitle(null);
    }
  }, [location.pathname]);

  return (
    <StyledHeader>
      <TextTituloHeader>{title}</TextTituloHeader>
      <Div>
        <DivSideText>
          <TextHeaderName>{formattedName()}</TextHeaderName>
          <TextHeaderEmail>
            {formattedEmail}
          </TextHeaderEmail>
        </DivSideText>
        <ExitButton onClick={() => setUserInfo(null)}>
          <Icon src={IconExit} />
        </ExitButton>
      </Div>
    </StyledHeader>
  );
};

Header.propTypes = {
  routesConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Header;
