import styled from 'styled-components';

const StyledToogle = styled.button`
  display: flex;
  height: 20px;
  width: 40px;
  border-radius: 100px;
  border: 1px solid #8C66FF;
  background: none;
  align-items: center;
  padding: 4px;
  display: flex;
  cursor: pointer;
  justify-content: ${({ checked }) => (checked
    ? 'flex-end'
    : 'flex-start')};
  background: ${({ checked }) => (checked
    ? '#8C66FF'
    : '#FFFFFF')};
`;

const InnerCicle = styled.div`
  width: 12.5px;
  border-radius: 100px;
  height: 12.5px;
  background: ${({ checked }) => (checked
    ? '#FFFFFF'
    : '#8C66FF')};
`;

export {
  StyledToogle,
  InnerCicle,
};
