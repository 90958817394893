import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TableHeader from '../tableHeaderRecord';
import TableCells from '../tableCellsRecord';

import arrowLeft from '../../assets/images/icon-arrowLeft.svg';
import arrowRight from '../../assets/images/icon-arrowRight.svg';

import {
  Table,
  TableRow,
  TableBody,
  PaginationDiv,
  TableDiv,
  PaginationText,
  PaginationButton,
  PaginationNav,
  PreviousButton,
  NextButton,
} from './styled';

const RecordTable = ({ records }) => {
  const currentPage = [];
  const [actualPage, setActualPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const itensPerPage = 12;
  const [maxItems, setMaxItems] = useState(0);
  const [pageItems, setPageItems] = useState([]);

  const countItems = (initial) => {
    records.slice(initial, initial + itensPerPage).map((item) => currentPage.push(item));
    setPageItems(currentPage);
  };

  const handlePaginationUp = () => {
    let start;
    if (startIndex + itensPerPage < maxItems) {
      start = startIndex + itensPerPage;
      setActualPage(actualPage + 1);
    } else {
      start = startIndex;
    }
    setStartIndex(start);
    countItems(start);
  };

  const handlePaginationDown = () => {
    let start;
    if (startIndex > 0) {
      start = startIndex - itensPerPage;
      setActualPage(actualPage - 1);
    } else {
      start = startIndex;
    }
    setStartIndex(start);
    countItems(start);
  };

  const handleResponsible = (responsible) => {
    let nickName = ' ';
    if (responsible) {
      nickName = responsible.nickname;
    }
    return nickName;
  };

  useEffect(() => {
    const total = Math.ceil(records.length / itensPerPage);
    setMaxItems(records.length);
    if (total === 0) {
      setTotalPages(1);
      setActualPage(1);
    } else {
      setTotalPages(total);
      setActualPage(1);
    }
    countItems(0);
  }, [records]);

  return (
    <TableDiv>
      <Table>
        <TableHeader headerText1="Nome completo" headerText2="data/hora" headerText3="Tipo de atividade" headerText4="pontos" headerText5="status" headerText6="responsável" />
        <TableBody>
          {pageItems.map((value) => (
            <TableRow key={value.id}>
              <TableCells
                name={value.activity.user.nickname}
                status={value.activity.status}
                activityType={value.activity.type.title}
                initialDate={value.createdAt}
                points={value.activity.coins}
                responsibleUser={handleResponsible(value.responsibleUser)}
                activityTitle={value.activity.title}
                finalDate={value.activity.finishDate}
                local={value.activity.place}
                link={value.activity.link}
                hours={value.activity.hours}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationDiv>
        <PaginationText>
          Página
          {' '}
          {actualPage}
          {' '}
          de
          {' '}
          {totalPages}
        </PaginationText>
        <PaginationNav>
          <PreviousButton>
            <PaginationButton src={arrowLeft} onClick={handlePaginationDown} />
          </PreviousButton>
          <NextButton>
            <PaginationButton src={arrowRight} onClick={handlePaginationUp} />
          </NextButton>
        </PaginationNav>
      </PaginationDiv>
    </TableDiv>
  );
};

RecordTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default RecordTable;
