import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';

const primaryStyle = css`
  background: #39BF66;  
  border: 1px solid #39BF66;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #32A658;
  }
  &:disabled{
    background: #1010104D;
    border-color:#1010104D;
    cursor: not-allowed;
  }
`;
const secondaryStyle = css`
  background: #FCFCFC;  
  border: 1px solid #39BF66;
  color: #39BF66;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #CEF2DA;
  }
  &:disabled{
    color: #1010104D;
    border-color:#1010104D;
    cursor: not-allowed;
  }
`;
const tertiaryStyle = css`
  background: #FFFFFF;;  
  border: 2px solid #D9626B;
  box-sizing: border-box;
  color: #D9626B;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  padding: 0px 16px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #FFE5E7;
  }
  &:disabled{
    color: #1010104D;
    border-color:#1010104D;
    cursor: not-allowed;
  }
`;
const quartiaryStyle = css`
  background: #FCFCFC;  
  border: 1px solid #8C66FF;
  color: #8C66FF;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #D9CCFF;
  }
  &:disabled{
    color: #1010104D;
    border-color:#1010104D;
    cursor: not-allowed;
  }
`;

const exclusionStyle = css`
  background: #D9626B; 
  border: 2px solid #D9626B;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  padding: 0px 16px;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #BF565F;
  } 
  &:disabled{
    color: #1010104D;
    border-color:#1010104D;
    cursor: not-allowed;
  }
`;

const loginStyle = css`
  width: 100%;
  background: #7E5CE5; 
  border: 1px solid  #7E5CE5;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 17px;
  border-radius: 16px;
  font-weight: bold;
  &:hover{
    background: #8C66FF; 
    border: 1px solid  #8C66FF;
  }
`;

const searchStyle = css`
  background: #8C66FF;
  border: 1px solid #8C66FF;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  &:active{
    background: #7052CC;
  }
`;

const getVariantStyle = (variant) => {
  if (variant === 'secondary') {
    return secondaryStyle;
  }
  if (variant === 'tertiary') {
    return tertiaryStyle;
  }
  if (variant === 'quartiary') {
    return quartiaryStyle;
  }
  if (variant === 'exclusion') {
    return exclusionStyle;
  }
  if (variant === 'login') {
    return loginStyle;
  }
  if (variant === 'search') {
    return searchStyle;
  }
  return primaryStyle;
};
const Icon = styled(ReactSVG)`
  width: 24px;
  height: 24px;
`;

const ButtonText = styled.p`
  margin-left: 8px;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  text-transform: uppercase;
  ${({ variant }) => getVariantStyle(variant)}
`;

export {
  StyledButton,
  Icon,
  ButtonText,
};
