import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  ModalBody,
  TextBody,
  TextArea,
} from './styled';

import Button from '../../button';

const ModalRequest = ({
  onClickCloseModal,
  onClickReject,
  message,
  title,
  value,
  onChange,
  reply,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const onSaving = () => {
    setIsSaving(true);
    onClickReject();
  };

  return (
    <ModalAll>
      <ModalDisplay>
        <ModalHeader>
          <ModalHeaderText>{title}</ModalHeaderText>
        </ModalHeader>
        <ModalBody>
          <TextBody>{message}</TextBody>
          {reply && (
            <TextArea
              noValidate
              autoComplete="off"
              value={value}
              onChange={onChange}
            />
          )}
          <ButtonsContainer>
            <Button onClick={onClickCloseModal} variant="tertiary" disabled={isSaving}>
              cancelar
            </Button>
            <Button onClick={onSaving} variant="primary" disabled={isSaving}>confirmar</Button>
          </ButtonsContainer>
        </ModalBody>
      </ModalDisplay>
    </ModalAll>
  );
};

ModalRequest.propTypes = {
  onClickCloseModal: PropTypes.func.isRequired,
  onClickReject: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  reply: PropTypes.bool.isRequired,
};

ModalRequest.defaultProps = {
  value: '',
  onChange: () => {},
};

export default ModalRequest;
