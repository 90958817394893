import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../badge';

import IconEdit from '../../assets/images/icon-editar.svg';
import IconDel from '../../assets/images/icon-deletar.svg';

import {
  CellLarge,
  CellSmall,
  CellTextLarge,
  CellTextSmall,
  EditIconButton,
  DeleteIconButton,
  EditButton,
  DeleteButton,
  DeleteAndEditButtonDiv,
} from './styled';

const TableCells = ({
  title, status, coins, multiplier, editItem, deleteItem,
}) => (
  <>
    <CellLarge>
      <CellTextLarge>{title}</CellTextLarge>
    </CellLarge>
    <CellSmall>
      { status ? <Badge variant="primary" value="ativo" /> : <Badge variant="secondary" value="desativado" />}
    </CellSmall>
    <CellSmall>
      {multiplier ? (
        <CellTextSmall>
          {coins}
          {' '}
          softcoins
          {' '}
          por
          {' '}
          hora
        </CellTextSmall>
      ) : (
        <CellTextSmall>
          {coins}
          {' '}
          softcoins
        </CellTextSmall>
      )}
    </CellSmall>
    <CellSmall>
      <DeleteAndEditButtonDiv>
        <EditButton onClick={editItem}>
          <EditIconButton src={IconEdit} />
        </EditButton>
      </DeleteAndEditButtonDiv>
      <DeleteAndEditButtonDiv>
        <DeleteButton onClick={deleteItem}>
          <DeleteIconButton src={IconDel} />
        </DeleteButton>
      </DeleteAndEditButtonDiv>
    </CellSmall>
  </>
);

TableCells.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  coins: PropTypes.string.isRequired,
  multiplier: PropTypes.bool,
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

TableCells.defaultProps = {
  multiplier: false,
};

export default TableCells;
