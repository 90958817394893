import React from 'react';
import RequestTable from '../../components/activityRequestTable';

import {
  Wrapper,
  Title,
  TableDiv,
} from './styled';

const ActivityRequest = () => (
  <Wrapper>
    <Title>Lista de solicitações de atividade</Title>
    <TableDiv>
      <RequestTable />
    </TableDiv>
  </Wrapper>
);

export default ActivityRequest;
