import styled from 'styled-components';

const ModalAll = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  border: 1px;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

`;
const ModalDisplay = styled.div`
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  width: 754px;
`;
const ModalHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 36px;
  background-color: #7052CC;
  border-radius: 8px 8px 0 0;
  padding-left: 24px;
`;
const ModalHeaderText = styled.div`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & button{
    margin-left: 16px;
  }
`;
const DivInputTextCOP = styled.div`
  display: flex;
  flex:2;
  flex-direction: column;
  padding-right: 24px;
`;
const DivInputTextPoints = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`;
const DivInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const DivTextArea = styled.div`
  display: flex;
  flex: 2;
  margin-right: 24px;
  flex-direction: column;
`;
const DivInputGracePeriod = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`;
const DivToggleStatus = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const DivBadge = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: min-content;
  & > p {
    font-weight: normal;
    font-size: 14px;
    margin-right: 8px;
  }
`;

const DivTextAreaToogle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const ModalBody = styled.div`
  padding: 32px 24px 24px 24px;
`;

const DivUpload = styled.div`
  display: flex;
  margin-bottom: 24px;
  max-height: 60px;
`;

export {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  DivInputTextCOP,
  DivInputTextPoints,
  DivInputs,
  DivTextArea,
  DivInputGracePeriod,
  DivToggleStatus,
  DivTextAreaToogle,
  ModalBody,
  DivBadge,
  DivUpload,
};
