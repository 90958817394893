import styled from 'styled-components';

const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableBody = styled.tbody`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0px;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
  &:nth-child(even) {
  background: #FAFAFA;
  }
`;

export {
  Table,
  TableRow,
  TableBody,
};
