import React from 'react';
import PropTypes from 'prop-types';

import {
  DivStyled,
  InputStyled,
  LabelStyled,
} from './styled';

const InputFieldRecord = ({
  onChange, value, placeholder, length,
}) => (
  <div>
    <DivStyled>
      <InputStyled
        noValidate
        autoComplete="off"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={length}
      />
      <LabelStyled>{placeholder}</LabelStyled>
    </DivStyled>
  </div>
);

InputFieldRecord.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  length: PropTypes.number,
};

InputFieldRecord.defaultProps = {
  length: 100,
};

export default InputFieldRecord;
