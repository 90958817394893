import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TableHeader from '../tableHeaderRequest';
import useActivitySearch from '../../hooks/useActivitySearch';

import IconActions from '../../assets/images/icon-Actions.svg';

import {
  Table,
  TableRow,
  CellLarge,
  CellSmall,
  CellText,
  IconButton,
  ActionButton,
  TableBody,
} from './styled';

const ActivityRequestTable = () => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);

  const {
    requests,
    hasMore,
    loading,
  } = useActivitySearch(pageNumber);

  const observer = useRef();
  const lastRequestRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const handleDate = (date) => {
    const [year, month, day] = date.split('-');
    const dateFormatter = `${day}/${month}/${year}`;
    return dateFormatter;
  };

  const handleActivityDetails = (request) => {
    history.push('/solicitacoes-atividade-detalhes', request);
  };

  return (
    <Table>
      <TableHeader headerText1="data" headerText2="colaborador" headerText3="atividade" headerText4="ação" />
      {requests.map((request, index) => {
        if (requests.length === index + 1) {
          return (
            <TableRow key={request.id}>
              <TableBody ref={lastRequestRef}>
                <CellSmall>
                  <CellText>
                    {handleDate(request.finishDate)}
                  </CellText>
                </CellSmall>
                <CellLarge>
                  <CellText>{request.user.nickname}</CellText>
                </CellLarge>
                <CellLarge>
                  <CellText>{request.type.title}</CellText>
                </CellLarge>
                <CellSmall>
                  <CellText>
                    <ActionButton>
                      <IconButton src={IconActions} />
                    </ActionButton>
                  </CellText>
                </CellSmall>
              </TableBody>
            </TableRow>
          );
        }
        return (
          <TableRow key={request.id}>
            <TableBody ref={lastRequestRef}>
              <CellSmall>
                <CellText>
                  {handleDate(request.finishDate)}
                </CellText>
              </CellSmall>
              <CellLarge>
                <CellText>{request.user.nickname}</CellText>
              </CellLarge>
              <CellLarge>
                <CellText>{request.type.title}</CellText>
              </CellLarge>
              <CellSmall>
                <CellText>
                  <ActionButton onClick={() => handleActivityDetails(request)}>
                    <IconButton src={IconActions} />
                  </ActionButton>
                </CellText>
              </CellSmall>
            </TableBody>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default ActivityRequestTable;
