import React from 'react';
import PropTypes from 'prop-types';
import { StyledBadge, BadgeText } from './styled';

const Badge = ({ variant, value }) => (
  <StyledBadge variant={variant}>
    <BadgeText>{value}</BadgeText>
  </StyledBadge>
);

Badge.propTypes = {
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};
Badge.defaultProps = {
  variant: 'primary',
};
export default Badge;
