import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const StyledCheckbox = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
  align-items: center;
  &:focus{
    path{
      fill: #8C66FF;
    }
  }
`;

const StyledCheckboxLabel = styled.label`
  font-weight: normal;
  font-size: 14px;
  margin-left: 8px;
`;

const Icon = styled(ReactSVG)`
  svg, div{
    width: 24px;
    height: 24px;
    margin: 0px;
    padding: 0px;
    path{
      ${({ disabled }) => (disabled && 'fill: #1010104D')};
    }
  }
`;

export {
  StyledCheckbox,
  StyledCheckboxLabel,
  Icon,
};
