import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Api from '../../services/api';
import ToastApi from '../../services/toast';

import ActivityRequestLogo from '../../assets/images/icon-activityRequest.svg';
import BenefitRequestLogo from '../../assets/images/icon-benefitRequest.svg';

import {
  Title,
  Wrapper,
  CardsDiv,
  Card,
  CardTitle,
  CardRequestNumber,
  CardRequestLink,
  Icon,
  Row,
} from './styled';

const benefitCardPlaceholder = 'Solicitações de Prêmios';
const activityCardPlaceholder = 'Solicitações de Atividade';
const cardLinkPlaceholder = 'Ver mais';

const DashboardPage = () => {
  const history = useHistory();

  const [benefitRequest, setBenefitRequest] = useState(0);
  const [activityRequest, setActivityRequest] = useState(0);

  const sanitezedRequestsNumber = (requests) => {
    if (requests > 9999) {
      return '9999+';
    }

    return requests;
  };

  useEffect(() => {
    Api.getDashboardInfo().then((res) => {
      setBenefitRequest(res.pendingBenefits);
      setActivityRequest(res.pendingActivities);
    }).catch(() => {
      ToastApi.error('Não foi possível carregar os dados.');
    });
  }, []);

  const handleBenefitRequest = () => {
    history.push('/solicitacoes-premio');
  };

  const handleActivityRequest = () => {
    history.push('/solicitacoes-atividade');
  };

  return (
    <Wrapper>
      <Title>Dashboard</Title>
      <CardsDiv>
        <Card>
          <CardTitle>{benefitCardPlaceholder}</CardTitle>
          <CardRequestNumber>{sanitezedRequestsNumber(benefitRequest)}</CardRequestNumber>
          <Row>
            <CardRequestLink onClick={() => handleBenefitRequest()}>
              {cardLinkPlaceholder}
            </CardRequestLink>
            <Icon src={BenefitRequestLogo} />
          </Row>
        </Card>
        <Card>
          <CardTitle>{activityCardPlaceholder}</CardTitle>
          <CardRequestNumber>{sanitezedRequestsNumber(activityRequest)}</CardRequestNumber>
          <Row>
            <CardRequestLink onClick={() => handleActivityRequest()}>
              {cardLinkPlaceholder}
            </CardRequestLink>
            <Icon src={ActivityRequestLogo} />
          </Row>
        </Card>
      </CardsDiv>
    </Wrapper>
  );
};

export default DashboardPage;
