import React from 'react';
import PropTypes from 'prop-types';

import {
  DivStyled,
  InputStyled,
  LabelStyled,
  DivError,
  ErrorText,
  DivNotError,
} from './styled';

const errorMessage = 'Este campo é obrigatório.';

const TextArea = ({
  onChange, value, placeholder, invalid, disabled,
}) => (
  <div>
    <DivStyled>
      <InputStyled
        noValidate
        autoComplete="off"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        invalid={invalid}
        disabled={disabled}
      />
      <LabelStyled>{placeholder}</LabelStyled>
    </DivStyled>
    <DivError>
      {invalid ? <ErrorText>{errorMessage}</ErrorText> : <DivNotError />}
    </DivError>
  </div>
);

TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextArea.defaultProps = {
  invalid: false,
  disabled: false,
};

export default TextArea;
