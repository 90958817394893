import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 44px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #7e5ce5;
  margin-bottom: 24px;
`;

const DetailsDiv = styled.div`
  width: 100%;
`;

const DivInputHours = styled.div`
  display: flex;
  flex: 1;
  padding-right: 32px;
  flex-direction: column;
`;

const DivInputCollaborator = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

const DivInputs = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const DivInputType = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`;

const DivInputDetails = styled.div`
  display: flex;
  flex: 1;
  height: 80px;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;
`;

const DivButton = styled.div`
  padding-right: 20px;
`;

export {
  Wrapper,
  Title,
  DetailsDiv,
  DivInputHours,
  DivInputCollaborator,
  DivInputs,
  DivInputType,
  DivInputDetails,
  ButtonsContainer,
  DivButton,
};
