import React, { useState, useEffect } from 'react';

import ToastApi from '../../services/toast';
import Api from '../../services/api';

import Button from '../../components/button';
import InputFieldRecord from '../../components/inputFieldRecord';
import DatePicker from '../../components/date';
import Select from '../../components/select';
import RecordTable from '../../components/recordTable';
import ExportFile from '../../components/exportFile';

import IconDownloadDisable from '../../assets/images/icon-downloadDisable.svg';
import IconSearch from '../../assets/images/icon-search.svg';

import {
  AlignFilters,
  TitleButtonContainer,
  Wrapper,
  Title,
  TitleHeader,
  FieldLarge,
  FieldSmall,
  FieldButton,
} from './styled';

const placeholderCollaborator = 'Colaborador';
const placeholderStatus = 'Status';
const placeholderInitialDate = 'Data Inicial';
const placeholderFinalDate = 'Data Final';
const placeholderActivity = 'Atividade';

const RecordPage = () => {
  const optionStatus = [
    {
      value: 'ALL',
      name: 'Todas',
    },
    {
      value: 'PENDING',
      name: 'Em análise',
    },
    {
      value: 'APPROVED',
      name: 'Aprovada',
    },
    {
      value: 'REJECTED',
      name: 'Rejeitada',
    },
  ];

  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [initial, setInitial] = useState('');
  const [final, setFinal] = useState('');
  const [records, setRecords] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const iniDate = new Date();
    const finDate = new Date();
    setFinal(iniDate);
    setInitial(finDate.setDate(finDate.getDate() - 30));
    Api.getActivities().then((res) => {
      const list = [{
        value: 'Todas',
        name: 'Todas',
      }];
      res.map((item) => list.push({ value: item.title, name: item.title }));
      setTitleList(list);
    }).catch(() => {
      ToastApi.error('Não pode ser exibida a lista');
    });
  }, []);

  const handleDate = (date) => {
    const newDate = new Date(date);
    const dateFormatter = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
    return dateFormatter;
  };

  const handleRecords = () => {
    const initialDate = handleDate(initial);
    const finalDate = handleDate(final);
    Api.getReportActivities(name, status, title, initialDate, finalDate).then((res) => {
      if (res.trackRequests.length > 100) {
        setRecords(res.trackRequests.slice(0, 100));
        ToastApi.error('Ops, tivemos mais de 100 resultados, reveja os parâmetros da pesquisa!');
      } else {
        setRecords(res.trackRequests);
      }
      setShowTable(true);
    }).catch(() => {
      ToastApi.error('Não pode ser exibida a lista');
    });
  };

  return (
    <Wrapper>
      <TitleHeader>
        <Title>Relatório de atividades</Title>
        {showTable
          ? (
            <ExportFile records={records} />
          ) : (
            <Button
              variant="quartiary"
              icon={IconDownloadDisable}
              disabled={!showTable}
            >
              exportar
            </Button>
          )}
      </TitleHeader>
      <TitleButtonContainer>
        Filtros:
      </TitleButtonContainer>
      <AlignFilters>
        <FieldLarge>
          <InputFieldRecord
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={placeholderCollaborator}
          />
        </FieldLarge>
        <FieldSmall>
          <Select
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            placeholder={placeholderStatus}
            options={optionStatus}
            selected={(e) => e.target.value}
          />
        </FieldSmall>
        <FieldSmall>
          <DatePicker
            onChange={(date) => setInitial(date)}
            value={initial}
            selected={initial}
            placeholder={placeholderInitialDate}
          />
        </FieldSmall>
        <FieldSmall>
          <DatePicker
            onChange={(date) => setFinal(date)}
            value={final}
            selected={final}
            placeholder={placeholderFinalDate}
          />
        </FieldSmall>
        <FieldLarge>
          <Select
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder={placeholderActivity}
            options={titleList}
          />
        </FieldLarge>
        <FieldButton>
          <Button
            onClick={handleRecords}
            variant="search"
            icon={IconSearch}
          />
        </FieldButton>
      </AlignFilters>
      {showTable && (
        <RecordTable
          records={records}
        />
      )}
    </Wrapper>
  );
};

export default RecordPage;
