import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';

const StyledMenu = styled.menu`
  background: #7052cc;
  width: ${({ size }) => (size
    ? '72'
    : '312')}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
`;

const SoftPoints = styled.h1`
  margin-top: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #ffff;
  margin-bottom: 24px;
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #7052CC;
  border: none;
  cursor: pointer;
  &:hover{
    background: #8C66FF;
  }
`;
const MenuText = styled.p`
  text-align: left;
  text-decoration: none;
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding-left: 16px;
  text-transform: uppercase;
`;

const MenuIcon = styled(ReactSVG)`
  width: 30px;
  margin-left: 20px;
`;

export {
  SoftPoints,
  StyledMenu,
  MenuItem,
  MenuIcon,
  MenuText,
};
