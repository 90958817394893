import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const CellLarge = styled.td`
  display: flex;
  height: 41px;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const CellSmall = styled.td`
  display: flex;
  height: 41px;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const CellTextLarge = styled.p`
  font-weight: normal;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #5B6166;
`;

const CellTextSmall = styled.p`
  font-weight: normal;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #5B6166;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
  &:nth-child(even) {
  background: #FAFAFA;
  }
`;

const EditIconButton = styled(ReactSVG)`
   svg, div{
    margin: 0px;
    padding: 0px;
  }
`;

const DeleteIconButton = styled(ReactSVG)`
  svg, div{
    margin: 0px;
    padding: 0px;
  }
`;

const EditButton = styled.button`
  height: 24px;
  width: 24px;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
`;

const DeleteButton = styled.button`
  height: 24px;
  width: 24px;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
`;
const DeleteAndEditButtonDiv = styled.div`
  height: 23px;
  width: 17px;
  background: transparent;
  border:none;
  padding: 10px;
`;

export {
  CellLarge,
  CellSmall,
  CellTextLarge,
  CellTextSmall,
  TableRow,
  EditIconButton,
  DeleteIconButton,
  EditButton,
  DeleteButton,
  DeleteAndEditButtonDiv,
};
