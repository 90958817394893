import axios from './axiosMiddleware';
import JwtService from '../jwtService';

const getActivities = async () => {
  const axiosResponse = await axios.get('/activities/types');
  return axiosResponse.data;
};

const deleteActivitie = async (id) => {
  const axiosResponse = await axios.delete(`/activities/types/${id}`);
  return axiosResponse.data;
};

const registerActivitie = async (title, description, coins, multiplier, flagHours, available) => {
  const axiosResponse = await axios.post('/activities/types',
    {
      title,
      description,
      coins,
      multiplier,
      flagHours,
      available,
    });
  return axiosResponse.data;
};

const editActivitie = async (id, title, description, coins, multiplier, flagHours, available) => {
  const axiosResponse = await axios.put(`/activities/types/${id}`,
    {
      title,
      description,
      coins,
      multiplier,
      flagHours,
      available,
    });
  return axiosResponse.data;
};

const getBenefits = async () => {
  const axiosResponse = await axios.get('/benefits/types?sort=title');
  return axiosResponse.data;
};

const registerBenefits = async (title, instructions, coins, available, gracePeriod, image) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('instructions', instructions);
  formData.append('coins', coins);
  formData.append('available', available);
  if (gracePeriod !== '') {
    formData.append('gracePeriod', gracePeriod);
  }
  formData.append('image', image);

  const axiosResponse = await axios.post('/benefits/types', formData, {
    headers: {
      // eslint-disable-next-line
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  return axiosResponse.data;
};

const deleteBenefit = async (id) => {
  const axiosResponse = await axios.delete(`/benefits/types/${id}`);
  return axiosResponse.data;
};

const editBenefits = async (id, title, instructions, coins, available, gracePeriod, image) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('instructions', instructions);
  formData.append('coins', coins);
  formData.append('available', available);

  if (image) {
    formData.append('image', image);
  }

  if (gracePeriod === '') {
    formData.append('gracePeriod', 0);
  } else {
    formData.append('gracePeriod', gracePeriod);
  }

  const axiosResponse = await axios.put(`/benefits/types/${id}`, formData, {
    headers: {
      // eslint-disable-next-line
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  return axiosResponse.data;
};

const getReportActivities = async (name, status, title, initialDate, finalDate) => {
  const params = {
    initialDate,
    finalDate,
  };
  if (name !== '') {
    params.name = name;
  }
  if (status !== '') {
    if (status !== 'ALL') {
      params.status = status;
    }
  }
  if (title !== '') {
    if (title !== 'Todas') {
      params.title = title;
    }
  }
  const axiosResponse = await axios.get('/reports/activities',
    {
      params,
    });

  return axiosResponse.data;
};

const getPendingActivities = async (page) => {
  const params = {
    status: 'PENDING',
    limit: 10,
    page,
  };

  const axiosResponse = await axios.get('/activities',
    {
      params,
    });
  return axiosResponse.data;
};

const getPendingbenefits = async (page) => {
  const params = {
    status: 'PENDING',
    limit: 10,
    page,
  };

  const axiosResponse = await axios.get('/benefits',
    {
      params,
    });
  return axiosResponse.data;
};

const setApiToken = (token) => {
  axios.defaults.headers.Authorization = token;
};

const login = async (username, password) => {
  const axiosResponse = await axios.post('/auth', {
    username,
    password,
  });

  return axiosResponse.data;
};

const getUserInfoFromToken = async (token) => {
  const userId = JwtService.decodeToken(token);

  const axiosResponse = await axios.get(`/users/${userId}`, {
    headers: {
      Authorization: token,
    },
  });

  return axiosResponse.data;
};

const rejectActivity = async (id, adminComment) => {
  const axiosResponse = await axios.put(`/activities/${id}/reject`, {
    adminComment,
  });
  return axiosResponse.data;
};

const approveActivity = async (id) => {
  const axiosResponse = await axios.put(`/activities/${id}/approve`);
  return axiosResponse.data;
};

const rejectBenefit = async (id, adminComment) => {
  const axiosResponse = await axios.put(`/benefits/${id}/reject`, {
    adminComment,
  });
  return axiosResponse.data;
};

const approveBenefit = async (id) => {
  const axiosResponse = await axios.put(`/benefits/${id}/approve`);
  return axiosResponse.data;
};

const getDashboardInfo = async () => {
  const axiosResponse = await axios.get('/dashboard');
  return axiosResponse.data;
};

export default {
  getActivities,
  deleteActivitie,
  registerActivitie,
  editActivitie,
  getBenefits,
  registerBenefits,
  deleteBenefit,
  editBenefits,
  getReportActivities,
  login,
  setApiToken,
  getPendingActivities,
  getPendingbenefits,
  rejectActivity,
  approveActivity,
  rejectBenefit,
  approveBenefit,
  getDashboardInfo,
  getUserInfoFromToken,
};
