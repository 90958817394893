import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const TableDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 2px 20px rgba(25, 6, 81, 0.1);
  border-radius: 6px 6px 0px 0px;
`;

const Table = styled.table`
  flex-direction: column;
  width: 100%;
`;

const TableBody = styled.tbody`
  flex: 1;
  flex-direction: column;
  margin: 0px;
`;

const TableRow = styled.tr`
  flex: 1;
  height: 41px;
  &:nth-child(even) {
  background: #FAFAFA;
  }
`;

const PaginationDiv = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: flex-end;
`;

const PaginationText = styled.h6`
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #787F82;
  padding-right: 20px;
`;

const PaginationButton = styled(ReactSVG)`
  width: 24px;
  height: 24px;
`;

const PaginationNav = styled.div`
  display: flex;
  padding: 0px 5px 0px 5px;
  align-items: center;
`;

const PreviousButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #FFFFFF;
  margin-right: 12px;
`;

const NextButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #FFFFFF;
  margin-left: 12px;
`;

export {
  Table,
  TableRow,
  TableBody,
  PaginationDiv,
  TableDiv,
  PaginationText,
  PaginationButton,
  PaginationNav,
  PreviousButton,
  NextButton,
};
