import { useEffect, useState } from 'react';
import Api from '../services/api';

const useActivitySearch = (page) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    Api.getPendingActivities(page).then((res) => {
      setRequests((prevRequests) => [...prevRequests, ...res]);
      setHasMore(res.length > 0);
      setLoading(false);
    }).catch(() => {
      setError(true);
    });
  }, [page]);

  return {
    loading,
    error,
    requests,
    hasMore,
  };
};

export default useActivitySearch;
