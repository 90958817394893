import { toast } from 'react-toastify';

const ToastApi = () => {
  const success = (message) => toast.success(message);
  const error = (message) => toast.error(message || 'unexpected error');
  const warning = (message) => toast.warning(message);

  return {
    success,
    error,
    warning,
  };
};

const toastApiInstance = ToastApi();

export default toastApiInstance;
