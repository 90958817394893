import styled from 'styled-components';

const StyledToolTip = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  &:hover label {
    opacity: 1;
    pointer-events: all;
  }
`;

const StyledToolTipText = styled.label`
  opacity: 0;
  transition: 0.1s ease-in-out;
  pointer-events: none;

  position: absolute;
  transform: translate(-50%, -100%);
  left: 50%;
  top: -6px;
  z-index: 10;

  width: max-content;
  padding: 8px 12px;

  background: #000000;
  border-radius: 4px;

  white-space: pre-wrap;
  text-align: center;
  color: #FFFF;
  font-size: 10px;
  
`;

export {
  StyledToolTip,
  StyledToolTipText,
};
