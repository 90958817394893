import styled from 'styled-components';

const AlignFilters = styled.div`
  margin-bottom: 24px;
  justify-content: center;
  display: flex;
  justify-content: space-between;
`;

const TitleButtonContainer = styled.div`
  margin-bottom: 16px;
  justify-content: flex-start;
  display:flex;
`;

const Wrapper = styled.div`
  width: calc(100% - 88px);
  padding: 12px 44px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7e5ce5;
`;

const TableDiv = styled.div`
  display: flex;
`;

const TitleHeader = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  flex: 1;
`;

const FieldLarge = styled.div`
  flex: 1.5;
  margin-right: 20px;
`;

const FieldSmall = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FieldButton = styled.div`
  width: 88px;
`;

export {
  AlignFilters,
  TitleButtonContainer,
  Wrapper,
  Title,
  TableDiv,
  TitleHeader,
  FieldLarge,
  FieldSmall,
  FieldButton,
};
