import JWTDecode from 'jwt-decode';

const verifyToken = (token) => {
  const decodedToken = JWTDecode(token);
  const newDate = Date.now();

  return decodedToken.exp * 1000 < newDate;
};

const decodeToken = (token) => {
  const decodedToken = JWTDecode(token);
  return decodedToken.sub;
};

export default {
  verifyToken,
  decodeToken,
};
