import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledToolTip,
  StyledToolTipText,
} from './styles';

const ToolTip = ({
  text, children, disabled,
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <StyledToolTip>
      {children}
      <StyledToolTipText>{text}</StyledToolTipText>
    </StyledToolTip>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

ToolTip.defaultProps = {
  disabled: false,
};

export default ToolTip;
