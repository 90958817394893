import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const CellLarge = styled.td`
  display: flex;
  height: 41px;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const CellSmall = styled.td`
  display: flex;
  height: 41px;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DivCollapseAlign = styled.div`
  display: flex;
  height: 41px;
  flex: 0.3;
  align-items: center;
  justify-content: center;
`;

const CellTextLarge = styled.p`
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  align-items: center;
  margin-left: 20px;
  color: #5B6166;
  overflow: hidden;
`;

const CellTextSmall = styled.p`
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  align-items: center;
  color: #5B6166;
  overflow: hidden;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
  background-color: ${({ isOpen }) => (isOpen
    ? '#DAE6F2'
    : '#FFFFFF')};
`;

const TableRowSub = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
`;

const Collapse = styled.div`
  align-items: center;
  min-height: 32px;
  flex-direction: column;
`;

const DropCollapseIcon = styled(ReactSVG)`
  svg, div{
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }
`;

const InfoCollapseButton = styled.button`
  background: transparent;
  border: none;
`;

const AlignTable = styled.div`
  width: 100%;
`;

const TableHead = styled.thead`
  background-color: #FCFCFC;
  display: flex;
  flex: 1;
  height: 32px;
  border-radius: 8px 8px 0px 0px;
  margin: 0px 42px 0px 42px;
`;

const HeaderTextCollapseLarge = styled.th`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
`;

const HeaderTextCollapseSmall = styled.th`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CollapseHeaderText = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  color: #8A9299;
`;

const CollapseCellLarge = styled.td`
  display: flex;
  height: 32px;
  flex: 2;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const CollapseCellSmall = styled.td`
  display: flex;
  height: 32px;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const AlignCollapse = styled.div`
  margin: 0px 42px 0px 42px;
`;

export {
  CellLarge,
  CellSmall,
  CellTextLarge,
  CellTextSmall,
  TableRow,
  TableRowSub,
  DivCollapseAlign,
  Collapse,
  DropCollapseIcon,
  InfoCollapseButton,
  AlignTable,
  HeaderTextCollapseLarge,
  HeaderTextCollapseSmall,
  TableHead,
  CollapseHeaderText,
  CollapseCellLarge,
  CollapseCellSmall,
  AlignCollapse,
};
