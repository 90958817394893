import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalAll,
  ModalDisplay,
  ModalHeader,
  ModalHeaderText,
  ButtonsContainer,
  ModalBody,
} from './styled';

import Button from '../../button';

const Modal = ({ onClickSecondaryButton, onClickDeleteButton, message }) => {
  const [isSaving, setIsSaving] = useState(false);
  const onSaving = () => {
    setIsSaving(true);
    onClickDeleteButton();
  };

  return (
    <ModalAll>
      <ModalDisplay>
        <ModalHeader>
          <ModalHeaderText>Opa!</ModalHeaderText>
        </ModalHeader>
        <ModalBody>
          <p>{message}</p>
          <ButtonsContainer>
            <Button onClick={onClickSecondaryButton} variant="tertiary" disabled={isSaving}>
              cancelar
            </Button>
            <Button onClick={onSaving} variant="exclusion" disabled={isSaving}>excluir</Button>
          </ButtonsContainer>
        </ModalBody>
      </ModalDisplay>
    </ModalAll>
  );
};

Modal.propTypes = {
  onClickSecondaryButton: PropTypes.func.isRequired,
  onClickDeleteButton: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default Modal;
