import axios from 'axios';
import JwtService from '../jwtService';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (window.location.pathname !== '/login') {
      const verifiedToken = JwtService.verifyToken(config.headers.Authorization);

      if (verifiedToken) {
        localStorage.removeItem('userInfo');
        window.location = '/login';
      }
    }
    return config;
  }, (error) => Promise.reject(error),
);

export default axiosInstance;
