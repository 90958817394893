import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import IconDownload from '../../assets/images/icon-Relatorio2.svg';

import {
  StyledButton,
  Icon,
} from './styled';

const ExportFile = ({ records }) => {
  const headers = [
    { label: 'Name', key: 'activity.user.nickname' },
    { label: 'Data/Hora', key: 'createdAt' },
    { label: 'Tipo de Atividade', key: 'activity.type.title' },
    { label: 'Pontos', key: 'activity.coins' },
    { label: 'Status', key: 'activity.status' },
    { label: 'Responsável', key: 'responsibleUser.nickname' },
    { label: 'Título da Atividade', key: 'activity.title' },
    { label: 'Data', key: 'activity.finishDate' },
    { label: 'Horas', key: 'activity.hours' },
    { label: 'Local', key: 'activity.place' },
    { label: 'Link/Comprovante', key: 'activity.link' },
  ];

  return (
    <CSVLink
      data={records}
      headers={headers}
      filename="Relatório_de_atividades.csv"
      target="_blank"
    >
      <StyledButton>
        <Icon src={IconDownload} />
      </StyledButton>
    </CSVLink>
  );
};

ExportFile.propTypes = {
  records: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default ExportFile;
