import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 88px);
  padding: 44px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7e5ce5;
  margin-bottom: 24px;
`;

const TableDiv = styled.div`
  display: flex;
`;

export {
  Wrapper,
  Title,
  TableDiv,
};
