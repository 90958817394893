import React, { useState, useEffect, useRef } from 'react';

import ToastApi from '../../services/toast';
import Api from '../../services/api';

import Button from '../../components/button';
import BenefitsTable from '../../components/benefitsTable';
import ModalRegisterReward from '../../components/modal/modalRegisterReward';
import ModalDeleteTask from '../../components/modal/modalDeleteTask';
import ModalEditReward from '../../components/modal/modalEditReward';

import ButtonIcon from '../../assets/images/icon-Registrar.svg';

import {
  ButtonContainer,
  Wrapper,
  Title,
  TableDiv,
} from './styled';

const RegisterRewards = () => {
  const [benefits, setBenefits] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);

  const deleteBenefitIdRef = useRef(null);
  const editBenefitRef = useRef(null);

  useEffect(() => {
    Api.getBenefits().then((res) => {
      setBenefits(res);
    }).catch(() => {
      ToastApi.error('Não pode ser exibida a lista');
    });
  }, [isModalEditVisible, isModalVisible]);

  const deleteBenefit = () => {
    Api.deleteBenefit(deleteBenefitIdRef.current).then(() => {
      setBenefits(benefits.filter((a) => (a.id !== deleteBenefitIdRef.current)));
      ToastApi.success('Planilha salva com sucesso.');
    }).catch(() => {
      ToastApi.error('Não é possível excluir essa atividade.');
    }).finally(() => {
      setIsModalDeleteVisible(false);
    });
  };

  return (
    <Wrapper>
      {isModalVisible && (
        <ModalRegisterReward
          onClickCloseModal={() => setIsModalVisible(!isModalVisible)}
          onSaveSuccess={(newBenefit) => {
            const newBenefits = [...benefits];
            newBenefits.push(newBenefit);
            newBenefits.sort((a, b) => {
              const textA = a.title.toUpperCase();
              const textB = b.title.toUpperCase();
              const biggerThen = (textA > textB) ? 1 : 0;
              return (textA < textB) ? -1 : biggerThen;
            });
            setBenefits(newBenefits);
          }}
        />
      )}
      {isModalDeleteVisible && (
        <ModalDeleteTask
          onClickSecondaryButton={() => setIsModalDeleteVisible(!isModalDeleteVisible)}
          onClickDeleteButton={() => deleteBenefit()}
          message="Tem certeza que deseja excluir esse prêmio?"
        />
      )}
      {isModalEditVisible && (
        <ModalEditReward
          onClickCloseModal={() => setIsModalEditVisible(!isModalEditVisible)}
          editData={editBenefitRef.current}
          onSaveSuccess={(editedBenefit) => {
            const index = benefits.findIndex((a) => a.id === editedBenefit.id);
            const newBenefits = [...benefits];
            newBenefits.splice(index, 1, editedBenefit);
            setBenefits(newBenefits);
          }}
        />
      )}
      <Title>Lista de prêmios</Title>
      <ButtonContainer>
        <Button
          onClick={() => setIsModalVisible(!isModalVisible)}
          variant="secondary"
          icon={ButtonIcon}
        >
          cadastrar prêmio
        </Button>
      </ButtonContainer>
      <TableDiv>
        <BenefitsTable
          benefits={benefits}
          editBenefit={(benefit) => {
            setIsModalEditVisible(!isModalEditVisible);
            editBenefitRef.current = benefit;
          }}
          deleteBenefit={(id) => {
            setIsModalDeleteVisible(!isModalDeleteVisible);
            deleteBenefitIdRef.current = id;
          }}
        />
      </TableDiv>
    </Wrapper>
  );
};

export default RegisterRewards;
