import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 88px);
  padding: 44px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 24px;
  justify-content: flex-end;
  display:flex;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7e5ce5;
  margin-bottom: 12px;
`;

const TableDiv = styled.div`
  display: flex;
`;

export {
  Title,
  Wrapper,
  ButtonContainer,
  TableDiv,
};
