import React from 'react';
import PropTypes from 'prop-types';

import {
  DivStyled,
  InputStyled,
  LabelStyled,
  DivError,
  ErrorText,
  DivNotError,
} from './styled';

const errorMessage = 'Este campo é obrigatório.';

const InputField = ({
  onChange, value, placeholder, invalid, length, disabled,
}) => (
  <div>
    <DivStyled>
      <InputStyled
        noValidate
        autoComplete="off"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        invalid={invalid}
        maxLength={length}
        disabled={disabled}
      />
      <LabelStyled>{placeholder}</LabelStyled>
    </DivStyled>
    <DivError>
      {invalid ? <ErrorText>{errorMessage}</ErrorText> : <DivNotError />}
    </DivError>
  </div>
);

InputField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  length: PropTypes.number,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  length: 100,
  disabled: false,
};

export default InputField;
