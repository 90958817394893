import React from 'react';
import PropTypes from 'prop-types';

import {
  DivStyled,
  SelectStyled,
  LabelStyled,
  StyledOption,
} from './styled';

const Select = ({
  onChange, value, placeholder, options, selected,
}) => (
  <DivStyled>
    <SelectStyled
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      selected={selected}
    >
      {options.map((option) => (
        <StyledOption value={option.value}>
          {option.name}
        </StyledOption>
      ))}
    </SelectStyled>
    <LabelStyled>{placeholder}</LabelStyled>
  </DivStyled>
);

Select.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  selected: PropTypes.func.isRequired,
};

Select.defaultProps = {
  value: null,
};

export default Select;
