import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableRow = styled.tr`
  display: flex;
  flex: 1;
  height: 41px;
  &:nth-child(even) {
  background: #FAFAFA;
  }
`;

const CellLarge = styled.td`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const CellSmall = styled.td`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  &:last-child {
    flex: 0.5;
    justify-content: flex-end;
  }
`;

const CellText = styled.p`
  font-weight: normal;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #5B6166;
`;

const TableBody = styled.tbody`
  display: flex;
  flex: 1;
  height: 41px;
  margin: 0px;
  padding: 0 20px 0 20px;
`;

const IconButton = styled(ReactSVG)`
  svg, div{
    margin: 0px;
    padding: 0px;
  }
`;

const ActionButton = styled.button`
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-right: 16px;
  border: none;
  background: transparent;
`;

export {
  Table,
  TableRow,
  CellLarge,
  CellSmall,
  CellText,
  IconButton,
  ActionButton,
  TableBody,
};
