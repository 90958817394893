import React from 'react';

import RequestTable from '../../components/benefitRequestTable';

import {
  Wrapper,
  Title,
  TableDiv,
} from './styled';

const benefitRequest = () => (
  <Wrapper>
    <Title>Lista de solicitações de prêmios</Title>
    <TableDiv>
      <RequestTable />
    </TableDiv>
  </Wrapper>
);

export default benefitRequest;
