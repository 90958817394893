import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledToogle,
  InnerCicle,
} from './styled';

const Toogle = ({
  onChange, checked,
}) => {
  const handleCheck = () => onChange(!checked);
  return (
    <StyledToogle onClick={handleCheck} checked={checked}>
      <InnerCicle checked={checked} />
    </StyledToogle>
  );
};

Toogle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Toogle;
