import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import DashboardLogo from '../../assets/images/icon-dashboard.svg';
import RegisterActivitiesLogo from '../../assets/images/icon-cadAtividades.svg';
import RegisterRewardLogo from '../../assets/images/icon-benefits.svg';
import RelatorioLogo from '../../assets/images/icon-Relatorio.svg';
import ActivityRequestLogo from '../../assets/images/icon-activityRequest.svg';
import BenefitRequestLogo from '../../assets/images/icon-benefitRequest.svg';

import {
  StyledMenu,
  SoftPoints,
  MenuItem,
  MenuIcon,
  MenuText,
} from './style';

const LateralMenu = ({ routesConfig }) => {
  const location = useLocation();
  const [pathname, setPathname] = useState(false);

  useEffect(() => {
    const findedRoute = routesConfig.find(
      (routeConfig) => routeConfig.path.startsWith(location.pathname),
    );
    if (!findedRoute) {
      return;
    }
    if (findedRoute.title === 'Relatórios') {
      setPathname(true);
    } else {
      setPathname(false);
    }
  }, [location.pathname]);

  return (
    <StyledMenu size={pathname}>
      {pathname
        ? (
          <SoftPoints>{' '}</SoftPoints>
        ) : (
          <SoftPoints>Softpoints</SoftPoints>
        )}
      <MenuItem to="/dashboard">
        <MenuIcon src={DashboardLogo} />
        {!pathname && (
        <MenuText>
          dashboard
        </MenuText>
        )}
      </MenuItem>
      <MenuItem to="/cadastroAtividade">
        <MenuIcon src={RegisterActivitiesLogo} />
        {!pathname && (
        <MenuText>
          atividades
        </MenuText>
        )}
      </MenuItem>
      <MenuItem to="/cadastroPremio">
        <MenuIcon src={RegisterRewardLogo} />
        {!pathname && (
        <MenuText>
          prêmios
        </MenuText>
        )}
      </MenuItem>
      <MenuItem to="/relatorio">
        <MenuIcon src={RelatorioLogo} />
        {!pathname && (
        <MenuText>
          relatórios
        </MenuText>
        )}
      </MenuItem>
      <MenuItem to="/solicitacoes-atividade">
        <MenuIcon src={ActivityRequestLogo} />
        {!pathname && (
        <MenuText>
          solicitações de atividade
        </MenuText>
        )}
      </MenuItem>
      <MenuItem to="/solicitacoes-premio">
        <MenuIcon src={BenefitRequestLogo} />
        {!pathname && (
        <MenuText>
          solicitações de prêmios
        </MenuText>
        )}
      </MenuItem>
    </StyledMenu>
  );
};

LateralMenu.propTypes = {
  routesConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LateralMenu;
