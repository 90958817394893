import styled from 'styled-components';

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Title = styled.h1`
  text-transform: uppercase;
`;
const ContentAndHeaderWrapper = styled.div`
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
`;
export {
  Title,
  PageWrapper,
  ContentAndHeaderWrapper,
};
