import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import InputLogin from '../../components/inputLogin';
import Button from '../../components/button';
import Api from '../../services/api';

import SoftIcon from '../../assets/images/icon-SoftLogo.svg';
import YellowForm from '../../assets/images/icon-loginPageYellow.svg';
import PurpleForm from '../../assets/images/icon-loginPagePurple.svg';

import {
  Wrapper,
  DivLogin,
  Title,
  SubTitle,
  Symbol,
  DivInput,
  DivButton,
  YellowLoginImage,
  PurpleLoginImage,
  DivError,
  TextError,
} from './styled';

const placeholderUser = 'usuario';
const placeholderPassword = 'senha';

const LoginPage = () => {
  const history = useHistory();
  const [, setUserInfo] = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginError, setLoginError] = useState(false);

  const [alreadyClickOnSave, setAlreadyClickOnSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (alreadyClickOnSave) {
      setLoginError(!username || username.length === 0 || !password || password.length === 0);
    }
  }, [username, password]);

  const HandleLogin = () => {
    const loginHasError = !username || username.length === 0 || !password || password.length === 0;

    setAlreadyClickOnSave(true);

    if (loginHasError) {
      setLoginError(loginHasError);
      return;
    }

    setIsSaving(true);

    localStorage.removeItem('userInfo');

    Api.login(username, password).then((token) => {
      Api.getUserInfoFromToken(token).then((userInfo) => {
        if (userInfo.isAdmin) {
          setUserInfo({
            token,
            username: userInfo.username,
            nickname: userInfo.nickname,
          });
          history.push('/dashboard');
        } else {
          setLoginError(true);
        }
      }).catch(() => {
        setLoginError(true);
      });
    }).catch(() => {
      setLoginError(true);
    }).finally(() => {
      setIsSaving(false);
    });
  };

  return (
    <Wrapper>
      <YellowLoginImage src={YellowForm} />
      <PurpleLoginImage src={PurpleForm} />
      <DivLogin>
        <Title>
          Softpoints
        </Title>
        <SubTitle>
          Entre com seu usuário da rede:
        </SubTitle>
        <DivInput>
          <InputLogin
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={placeholderUser}
            invalid={loginError}
            type="text"
          />
        </DivInput>
        <DivInput>
          <InputLogin
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={placeholderPassword}
            invalid={loginError}
            type="password"
          />
        </DivInput>
        <DivButton>
          <Button
            onClick={() => HandleLogin()}
            variant="login"
            disabled={isSaving}
          >
            entrar
          </Button>
        </DivButton>
        <DivError>
          {loginError && (
            <TextError>
              O login falhou. Tente de novo ou entre em contato com o suporte.
            </TextError>
          )}
        </DivError>
        <Symbol src={SoftIcon} />
      </DivLogin>
    </Wrapper>
  );
};

export default LoginPage;
